import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { flexUnit } from '../../styles/mixins';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Number = styled.span`
  ${flexUnit(5, 24, 40)}
  font-weight: bold;
  line-height: 1.4;
  color: #a2a8b7;
  width: 2em;
`;

const Label = styled.span`
  ${flexUnit(3, 18, 24)}
  font-weight: bold;
  line-height: 0.75;
  color: #777777;
`;

const OrderedListItem = ({ index, children }) => (
  <Wrapper>
    <Number>{index}</Number>
    <Label>{children}</Label>
  </Wrapper>
);

OrderedListItem.propTypes = {
  index: PropTypes.string,
  children: PropTypes.any,
};

OrderedListItem.defaultProps = {};

export default OrderedListItem;
