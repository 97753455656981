import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { device, flexUnit } from '../../styles/mixins';

const StyledLink = styled(Link)`
  && {
    display: inline-block;
    padding: 0 55px;
    border-radius: 33px;
    border: solid 3px #947ec7;
    ${flexUnit(5, 18, 22)};
    font-weight: 600;
    line-height: 62px;
    text-decoration: none;
    color: #947ec7;
    white-space: nowrap;
  }

  @media ${device.phoneOnly} {
    && {
      width: calc(100% - 116px); // remove padding & border to fix width
      padding: 10px auto;
      text-align: center;
    }
  }
`;

const StyledA = styled.a`
  && {
    display: inline-block;
    padding: 0 55px;
    border-radius: 33px;
    border: solid 3px #947ec7;
    ${flexUnit(5, 18, 22)};
    font-weight: 600;
    line-height: 62px;
    text-decoration: none;
    color: #947ec7;
    white-space: nowrap;
  }

  @media ${device.phoneOnly} {
    && {
      width: calc(100% - 116px); // remove padding & border to fix width
      padding: 10px auto;
      text-align: center;
    }
  }
`;

const Button = ({ to, href, children }) => {
  if (to) {
    return <StyledLink to={to}>{children}</StyledLink>;
  }
  if (href) {
    return (
      <StyledA href={href} target="_blank" rel="noopener nofollow">
        {children}
      </StyledA>
    );
  }
};

Button.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.string,
};

Button.defaultProps = {
  children: 'Click me',
};

export default Button;
