import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
// import { Link } from 'react-router-dom';

import { device } from '../../styles/mixins';
import ListItem from './ListItem';

import data from './case-studies';

const Container = styled.div`
  padding: 50px 23px 0;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ListItemWrapper = styled.a`
  flex: 1 1 100%;
  padding: 5px 5px 45px 5px;
  text-decoration: none;

  @media ${device.tabletLandscapeUp} {
    flex: 1 1 45%;
  }
`;

const CaseStudyList = props => (
  <Container>
    <h1>
      <center>{props.title}</center>
    </h1>
    <ListContainer>
      {data.map((caseStudy, index) => {
        if (props.limit && index >= props.limit) {
          return null;
        }
        return (
          <ListItemWrapper
            key={caseStudy.name}
            href={caseStudy.slug}
            target="_blank"
            rel="nofollow noopener"
          >
            <ListItem
              name={caseStudy.name}
              image={caseStudy.image}
              tags={caseStudy.tags}
            />
          </ListItemWrapper>
        );
      })}
    </ListContainer>
  </Container>
);

CaseStudyList.propTypes = {
  title: PropTypes.string,
  limit: PropTypes.number,
};

CaseStudyList.defaultProps = {
  title: 'Case Studies',
};

export default CaseStudyList;
