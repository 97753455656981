import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { device, flexUnit } from '../../styles/mixins';

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props =>
    props.imagePosition === 'right' ? 'row' : 'row-reverse'};

  @media ${device.phoneOnly} {
    flex-direction: column-reverse;
  }
`;

const Title = styled.h1`
  ${flexUnit(5, 24, 36)};
  margin-top: 0.2em;

  @media ${device.phoneOnly} {
    margin-top: 0.5em;
  }
`;

const ContentWrapper = styled.div`
  align-self: center;
  flex: 7;

  display: grid;
  grid-template-columns: 2fr [content] 5fr;

  @media ${device.tabletPortraitDown} {
    grid-template-columns: 24px [content] 1fr 24px;
  }
`;

const Content = styled.div`
  grid-column-start: content;

  font-size: 20px;
  line-height: 1.8;
  color: #777777;
  font-weight: 400;
`;

const ImageWrapper = styled.div`
  flex: 4;
`;

const ContentBlock = ({
  title,
  image,
  imageAltText,
  imagePosition,
  children,
  ...otherProps
}) => (
  <Wrapper imagePosition={imagePosition} {...otherProps}>
    <ContentWrapper>
      <Content>
        {title && <Title>{title}</Title>}
        {children}
      </Content>
    </ContentWrapper>
    <ImageWrapper>
      {image && (
        <img src={image} alt={imageAltText} style={{ width: '100%' }} />
      )}
    </ImageWrapper>
  </Wrapper>
);

ContentBlock.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  imageAltText: PropTypes.string,
  imagePosition: PropTypes.string,
  children: PropTypes.node,
};

ContentBlock.defaultProps = {
  imagePosition: 'right',
  imageAltText: '',
};

export default ContentBlock;
