import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Image from '../../Image';

import { flexUnit } from '../../../styles/mixins';

const Container = styled.div`
  img {
    width: 100%;
  }
`;

const Name = styled.div`
  ${flexUnit(4, 16, 20)};
  font-weight: 600;
  line-height: 2;
  color: #435172;
  margin-left: 10px;
`;

const Tags = styled.div`
  ${flexUnit(3, 11, 14)};
  font-weight: 500;
  line-height: 1.43;
  color: #9e9e9e;
  margin-left: 10px;

  display: flex;
  flex-direction: row;

  span::after {
    content: '/';
    margin: 0 0.5em;
  }

  span:last-child::after {
    content: none;
  }
`;

const ListItem = ({ name, tags, image }) => {
  return (
    <Container>
      <Image sources={image.sources} alt="" />
      <Name>{name}</Name>
      <Tags>
        {tags.map(tag => (
          <span key={`${name}-${tag}`}>{tag}</span>
        ))}
      </Tags>
    </Container>
  );
};

ListItem.propTypes = {
  name: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.any,
};

ListItem.defaultProps = {
  tags: [],
};

export default ListItem;
