import React from 'react';

import Button from '../../components/Button';
import PageLayout from '../../components/PageLayout';
import ClientLogos from '../../components/ClientLogos';
import Row from '../../components/Row';

import ContentBlock from '../../components/ContentBlock';
import HowWeWork from '../../components/HowWeWork';
import CaseStudyList from '../../components/CaseStudyList';
import OrderedListItem from '../../components/OrderedListItem';
import TechnologyBlock from '../../components/TechnologyBlock';
import LetsWorkTogether from '../../components/LetsWorkTogether';

import Background from './images/header-home-bg.png';
import WhatWeDoVisual from './images/ilus-home-what.svg';
import ServicesVisual from './images/ilus-home-services.svg';

import clients from './clients';

const HomePage = () => (
  <PageLayout
    title="Home"
    header="Digital Expertise Lab.<br/>Bright and Solid Design."
    intro="We help startups in early stages to take off and support them along their project life cycle."
    visual={Background}
    additionalContent={<ClientLogos clients={clients} />}
  >
    <Row>
      <ContentBlock title="What we do" image={WhatWeDoVisual}>
        <p>
          We are a <strong>digital design studio</strong> loving startups.
        </p>
        <p>
          Our team helps businesses launch and improve their{' '}
          <strong>products</strong> and <strong>services</strong> to reach their
          objective.
        </p>
        <p>
          We deliver solutions fast and on time, always based on{' '}
          <strong>data-driven</strong> decisions.
        </p>
        <Button to="/about">About our Studio</Button>
      </ContentBlock>
    </Row>

    <HowWeWork />

    <Row>
      <CaseStudyList limit={4} />
      <div
        style={{
          marginTop: '10px',
          padding: '44px',
          borderTop: '1px dashed #979797',
          textAlign: 'center',
        }}
      >
        <Button to="/case-studies">See all Case Studies</Button>
      </div>
    </Row>

    <Row>
      <ContentBlock title="Services" image={ServicesVisual}>
        <OrderedListItem index="01">User Experience Design</OrderedListItem>
        <OrderedListItem index="02">Data-Driven Design</OrderedListItem>
        <OrderedListItem index="03">Design Systems</OrderedListItem>
        <OrderedListItem index="04">Prototyping</OrderedListItem>
        <OrderedListItem index="05">Interaction Design</OrderedListItem>
        <OrderedListItem index="06">Brand Identity</OrderedListItem>
        <OrderedListItem index="07">Sprint Facilitators</OrderedListItem>
        <br />
        <Button to="/services">Discover our services</Button>
      </ContentBlock>
    </Row>
    <Row>
      <TechnologyBlock>
        A good workflow and methodology will always drive our projects forward,
        therefore we stay up-to-date with our tech stacks.
      </TechnologyBlock>
    </Row>

    <Row>
      <LetsWorkTogether />
    </Row>
  </PageLayout>
);

export default HomePage;
