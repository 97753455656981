import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { device, flexUnit } from '../../styles/mixins';

import BackgroundImage from './images/ilus-rocket.svg';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  margin-top: 180px;
  z-index: 0;

  @media ${device.phoneOnly} {
    margin-top: 50px;
  }
`;

const Background = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 100vw;
  }

  @media ${device.tabletLandscapeDown} {
    img {
      width: 1200px;
    }
  }
`;

const Content = styled.div`
  position: absolute;
  top: 90px;
  left: 0;
  right: 0;
  text-align: center;

  @media ${device.phoneOnly} {
    top: 60px;
  }
`;

const Title = styled.div`
  ${flexUnit(5, 20, 36)}
  font-size: 36px;
  font-weight: bold;
  line-height: 0.64;
  color: #ffffff;

  @media ${device.phoneOnly} {
    font-weight: 700;
  }
`;

const SubTitle = styled.div`
  ${flexUnit(4, 14, 20)}
  font-weight: 400;
  line-height: 1.2;
  color: #ffffff;
  max-width: 50ch;
  margin: 50px auto;

  @media ${device.phoneOnly} {
    margin: 30px 24px;
  }
`;

const HowWeWork = () => (
  <Container>
    <Background>
      <img src={BackgroundImage} alt="" />
    </Background>
    <Content>
      <Title>How we work</Title>
      <SubTitle>
        We take methodologies very seriously in
        <br />
        Scrum and agile frameworks.
      </SubTitle>
    </Content>
  </Container>
);

HowWeWork.propTypes = {};

HowWeWork.defaultProps = {};

export default HowWeWork;
