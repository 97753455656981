import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import TechBlock from './TechBlock';

import { device } from '../../styles/mixins';
import data from './technology';

const Container = styled.div`
  margin: 100px 26px;
`;

const Content = styled.div`
  font-size: 20px;
  line-height: 1.8;
  color: #777777;
  font-weight: 400;

  @media ${device.tabletPortraitUp} {
    max-width: 450px;
    margin: 0 auto 2em auto;
    text-align: center;
  }
`;

const TechBlocks = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > * {
    flex: 1;
    margin: 8px;
  }

  @media ${device.tabletLandscapeUp} {
    flex-direction: row;
    margin: 0 auto 2em auto;
    text-align: center;
    margin: 1em;
  }
`;

const TechnologyBlock = ({ children }) => (
  <Container>
    <center>
      <h1>Technology</h1>
    </center>
    <Content>{children}</Content>
    <TechBlocks>
      {data.map(block => (
        <TechBlock key={block.name} name={block.name} items={block.items} />
      ))}
    </TechBlocks>
  </Container>
);

TechnologyBlock.propTypes = {
  children: PropTypes.any,
};

TechnologyBlock.defaultProps = {};

export default TechnologyBlock;
