import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Image from '../Image';
import { device } from '../../styles/mixins';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
`;

const ImageContainer = styled.div`
  display: none;
  margin: 5px;

  @media ${device.tabletLandscapeUp} {
    display: inline-block;
  }
`;

const ClientLogos = ({ clients }) => (
  <Container>
    {clients.map(client => (
      <ImageContainer key={client.name}>
        <Image sources={client.image.sources} alt={client.name} />
      </ImageContainer>
    ))}
  </Container>
);

ClientLogos.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, image: PropTypes.object })
  ),
};

ClientLogos.defaultProps = {};

export default ClientLogos;
