import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Picture } from 'react-responsive-picture';

const StyledPicture = styled(Picture)`
  max-width: 100% !important;
  img {
    max-width: 100% !important;
  }
`;

const Image = ({ sources }) => <StyledPicture sources={sources} />;

Image.propTypes = {
  src: PropTypes.string,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      srcSet: PropTypes.string,
      media: PropTypes.string,
    })
  ),
  sizes: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};

Image.defaultProps = {};

export default Image;
