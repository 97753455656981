import React from 'react';
import { useLocation } from 'react-router';
import ContentBlock from '../ContentBlock';
import Button from '../Button';
import WorkTogetherVisual from './images/ilus-contact-footer.svg';

const LetsWorkTogether = () => (
  <ContentBlock
    title="Let's work together"
    image={WorkTogetherVisual}
    imagePosition="left"
    id="get-in-touch"
  >
    <p>
      Have a project, proposal or idea?{' '}
      <a
        href="mailto:hello@361studio.co"
        style={{ fontWeight: 'bold', textDecoration: 'none' }}
      >
        hello@361studio.co
      </a>
    </p>
    <p>
      Our headquarters are located in Amsterdam,
      <br />
      but we love travelling 🚲
    </p>
    {useLocation().pathname === '/contact' ? (
      <Button href="mailto:hello@361studio.co">Get in touch!</Button>
    ) : (
      <Button to="/contact">Get in touch!</Button>
    )}
  </ContentBlock>
);

LetsWorkTogether.propTypes = {};

LetsWorkTogether.defaultProps = {};

export default LetsWorkTogether;
