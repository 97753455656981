import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { device, flexUnit } from '../../../styles/mixins';

const Container = styled.div`
  border-radius: 20px;
  box-shadow: 0 10px 25px 0 #dde7ee;
  background-color: #ffffff;
  margin: 5px 10px 20px 10px;

  flex: 1 1 100%;

  @media ${device.tabletLandscapeUp} {
    flex: 1 1 45%;
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: #83c7ff;
  margin: 1em;
  ${flexUnit(4, 12, 24)};
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 2em;
`;

const Icon = styled.div`
  img {
    max-height: 50px;
  }

  @media ${device.tabletLandscapeUp} {
    img {
      max-height: 60px;
    }
  }
`;

const Labels = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  ${flexUnit(4, 10, 22)};
  line-height: 1.64;
  text-align: center;
  color: #9b9b9b;
  margin-bottom: 2em;

  span {
    white-space: nowrap;
  }

  span::after {
    content: ',';
    margin-right: 0.5em;
  }

  span:last-child::after {
    content: none;
  }
`;

const TechBlock = ({ name, items }) => (
  <Container>
    <Title>{name}</Title>
    <Icons>
      {items.map(item => (
        <Icon key={item.name}>
          <img src={require(`${item.image}`)} alt={item.name} />
        </Icon>
      ))}
    </Icons>
    <Labels>
      {items.map(item => (
        <span key={item.name}>{item.name}</span>
      ))}
    </Labels>
  </Container>
);

TechBlock.propTypes = {
  name: PropTypes.string,
  items: PropTypes.array,
};

TechBlock.defaultProps = {
  items: [],
};

export default TechBlock;
